/**
  This is to align buttons properly in ag-grid cell
**/

.unassigned-start-button {
  margin-left: 1rem;
  display: inline-block;
}

.unassigned-view-button {
  display: inline-block;
}
