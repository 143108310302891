.cell-red {
  text-align: center;
  font-weight: bold;
  box-shadow: inset 5px 0px #e65c6a;
  background-color: #f0f0f0;
}

.cell-green {
  text-align: center;
  font-weight: bold;
  box-shadow: inset 5px 0px #9fe2ae;
  background-color: #f0f0f0;
}
.cell-amber {
  text-align: center;
  font-weight: bold;
  box-shadow: inset 5px 0px#f2cc79;
  background-color: #f0f0f0;
}
