.ag-theme-material {
  --ag-header-background-color: #d0d0d0;
  --ag-background-color: #f8f9fa;
  --ag-row-height: "52px";
  --ag-data-color: #3e3e3e;
  // --ag-cell-horizontal-padding: 0;

  .ag-row {
    border-bottom: 2px solid #e3e3e3;
  }

  .ag-cell {
    padding-right: 0;
  }
}
// Refer here - https://www.ag-grid.com/react-data-grid/global-style-customisation-variables/
