/** This is to align the buttons inside cell of ag-grid **/

.inprogress-view-button {
  display: inline-block;
  margin-right: 12px;
}

.inprogress-continue-button {
  display: inline-block;
}
